import { useEffect, useState } from "react";
import { useTonConnectUI, useTonAddress } from '@tonconnect/ui-react';
import { NavLink } from "react-router-dom";
import { useInitData, retrieveLaunchParams } from '@telegram-apps/sdk-react';

import sprite1 from "../../assets/img/sprite/mushie.gif";
import sprite2 from "../../assets/img/sprite/cyclopsie.gif";
import sprite3 from "../../assets/img/sprite/blobbo.gif";
import sprite4 from "../../assets/img/sprite/cyclopsie.gif";
import sprite5 from "../../assets/img/sprite/gaius.gif";
import sprite6 from "../../assets/img/sprite/reddik.gif";
import sprite7 from "../../assets/img/sprite/trilux.gif";
import sprite8 from "../../assets/img/sprite/echo.gif";
import sprite9 from "../../assets/img/sprite/tuskhorn.gif";

export default function Home() {

    const [userData, setUserData] = useState<any>();
    const [isCheckingModalOpen, setCheckingModalOpen] = useState(false);

    const sprite_list = [
        {
            id: 1,
            name: "Mushie",
            img: sprite1,
            price: 14
        },
        {
            id: 2,
            name: "Trilux",
            img: sprite2,
            price: 3.43
        },
        {
            id: 4,
            name: "Slimny",
            img: sprite3,
            price: 15.2
        },
        {
            id: 5,
            name: "Purplo",
            img: sprite4,
            price: 5.1
        },
        {
            id: 3,
            name: "Rekdit",
            img: sprite5,
            price: 22
        },
        {
            id: 4,
            name: "Slimny",
            img: sprite6,
            price: 19.32
        },
        {
            id: 3,
            name: "Rekdit",
            img: sprite7,
            price: 2.1
        },
        {
            id: 4,
            name: "Slimny",
            img: sprite8,
            price: 5.9
        },
        {
            id: 5,
            name: "Purplo",
            img: sprite9,
            price: 15
        },
        ,
        {
            id: 3,
            name: "Rekdit",
            img: sprite3,
            price: 22
        },
        {
            id: 5,
            name: "Purplo",
            img: sprite4,
            price: 52
        },
        
        {
            id: 3,
            name: "Rekdit",
            img: sprite5,
            price: 221
        }, {
            id: 4,
            name: "Slimny",
            img: sprite6,
            price: 98
        },
    ]

    useEffect(() => {
        
	}, []);

    return(
        <>
            <div className="relative market">
                <div className="frame-2">
                    <div className="frame-2-inner">
                        <div className="head">MARKETPLACE</div>
                        <div className="badge-tab flex items-center justify-center gap-1">
                            <NavLink to="/market" className="market-tab-item current"
                            >
                                SPRITE
                            </NavLink>
                            <NavLink to="/market/items" className="market-tab-item"
                            >
                                ITEMS
                            </NavLink>
                        </div>
                        <div className="sprite-list grid grid-cols-3 gap-1 gap-y-3">
                            { sprite_list &&
                                sprite_list.map((item, index) => (
                                    <NavLink to="" className="sprite-item opacity-40">
                                        <div className="sprite-item-inner">
                                            <div className="img">
                                                <img src={item.img} />
                                            </div>
                                            <div className="energy">{item.price}</div>
                                        </div>
                                        <button className="buy-market"></button>
                                    </NavLink>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}