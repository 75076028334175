import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import toncoin from "../../assets/img/ton-coin.png";

export default function Home() {

    const [userData, setUserData] = useState<any>();
    const [isCheckingModalOpen, setCheckingModalOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        
	}, []);

    // const newquest = [
    //     {
    //         id: 1,
    //         name: "Share your OG status",
    //         introl: "In telegram stories",
    //         price: 120
    //     },
    //     {
    //         id: 2,
    //         name: "Share your OG status",
    //         introl: "In telegram stories",
    //         price: 120
    //     },
    //     {
    //         id: 3,
    //         name: "Share your OG status",
    //         introl: "In telegram stories",
    //         price: 120
    //     },
    //     {
    //         id: 4,
    //         name: "Share your OG status",
    //         introl: "In telegram stories",
    //         price: 120
    //     },
    //     {
    //         id: 5,
    //         name: "Share your OG status",
    //         introl: "In telegram stories",
    //         price: 120
    //     },
    //     {
    //         id: 6,
    //         name: "Share your OG status",
    //         introl: "In telegram stories",
    //         price: 120
    //     },
    // ]

    const newquest = [
        {
            id: 1,
            name: "Connect wallet",
            introl: "Connect TON wallet",
            price: 1000
        }
    ];

    return(
        <>
            <div className="relative leaderboard">
                <div className="frame-3">
                    <div className="frame-2-inner">
                        <div className="head">NEW QUEST</div>
                        <div className="leaderboard-wrp">
                            <div className="newquest">
                                {
                                    newquest &&
                                        newquest.map((item) => (
                                            <div className="item mb-2">
                                                <div className="flex align-items gap-3">
                                                    <div>
                                                        <p className="w-[111px]"><img src={toncoin} /></p>
                                                    </div>
                                                    <div>
                                                        <p className=" font-medium text-[#000]">{item.name}</p>
                                                        <p className="text-[#363636]" >{item.introl}</p>
                                                        <div className="price mb-1">
                                                            <p className="uppercase font-medium text-[20px] text-[#FDCD0D]">+{item.price.toLocaleString('en-US')}</p>
                                                            <NavLink className="inline-block btn is-small text-center" to="/wallet">GO</NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}