import axios from "axios";
// import { useInitData, retrieveLaunchParams } from '@telegram-apps/sdk-react';

const api_endpoint = process.env.REACT_APP_API;

export const getUser = async (teleId) => {
    try {
        const result = await axios.get(`${api_endpoint}/users/${teleId}`);
        return result.data.data
    } catch (error) {
        console.log(`error`, error);
        return
    }
}

export const addUser = async (teleUser, refFrom, initDataRaw) => {
  const result = await axios.post(`${api_endpoint}/users/create`, {
      tele_id: teleUser.id,
      tele_username: teleUser.username ? teleUser.username : teleUser.id,
      name: `${teleUser.firstName} ${teleUser.lastName}`,
      ref_from: refFrom
    }, {
      headers: {
        'Authorization': `tma ${initDataRaw}` 
      }
    }
  );
  return result.data.user;
};

export const addPoint = async (data, initDataRaw) => {
  const result = await axios.post(`${process.env.REACT_APP_API}/users/point`, {
        id: data.tg_id,
        amount: data.amount, 
        wallet: data.wallet, 
        point_type: data.point_type
      }, {
        headers: {
          'Authorization': `tma ${initDataRaw}` 
        }
      }
  );
  return result.data.user
};

export const createFirstSprite = async (user_id, initDataRaw) => {
  const result = await axios.post(`${api_endpoint}/app/createFirstSprite`, {
      user_id: user_id
    }, {
      headers: {
        'Authorization': `tma ${initDataRaw}`
      }
    }
  );
  return result.data;
};

export const getSpriteDetail = async (id: number) => {
  try {
      const result = await axios.get(`${api_endpoint}/app/getSpriteDetail/${id}`);
      return result.data.data
  } catch (error) {
      console.log(`error`, error);
      return
  }
}

export const fighting = async (user_id, item_id, map_id, initDataRaw) => {
  const result = await axios.post(`${api_endpoint}/app/fighting`, {
      user_id: user_id,
      item_id: item_id,
      map_id: map_id
    }, {
      headers: {
        'Authorization': `tma ${initDataRaw}`
      }
    }
  );
  return result.data;
};

export const withdraw = async (userId: number, amount: number, toWallet: string, initDataRaw) => {
    try {
        const result = await axios.post(`${api_endpoint}/wallet/withdraw`, {
            user_id: userId,
            amount: amount,
            to_wallet: toWallet
          }, {
            headers: {
              'Authorization': `tma ${initDataRaw}` 
            }
          }
        );

        return {
            status: `success`,
            message: `Withdrawal is being processed.`,
            data: result.data.txn
        };

    } catch (error) {
        console.log(`error`, error)
        return {
            status: `error`,
            message: `Unable to Withdrawal.`
        };
    }
};


export const getTonTxnByUser = async (userId: number) => {
    const result = await axios.get(`${api_endpoint}/wallet/txns/${userId}`);
    return result.data.txns
}