import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { useTonConnectUI, useTonAddress } from '@tonconnect/ui-react';
import { Form } from "antd";
import { TonConnectButton } from '@tonconnect/ui-react';
import { Modal } from "antd";
import { useInitData, retrieveLaunchParams } from '@telegram-apps/sdk-react';
import { initUtils } from '@telegram-apps/sdk';
import { getTonBalance, truncateString, getAvatar, getTxnType } from '../../web3/ultils';
import toast from "react-hot-toast";
import tonCoin from "../../assets/img/ton.png";
import logo from "../../assets/img/logo.png";
import { getUser, addPoint, getTonTxnByUser } from '../../web3/app';

const systemAddress = process.env.REACT_APP_SYSTEM_ADDRESS;

export default function Wallet() {
    
    const navigate = useNavigate();
    const [tonConnect] = useTonConnectUI();
    const walletAddress = useTonAddress();
    const [userData, setUserData] = useState<any>();
    const [tonBalance, setTonBalance] = useState(0);

    const [isRefModalOpen, setRefModalOpen] = useState(false);


    const { initDataRaw } = retrieveLaunchParams();
    const initData = useInitData();
    const teleUser = initData.user;
    let utils = initUtils();

    // let utils;
    // let initDataRaw;
    // let teleUser = {
    //     id: `740772070`,
    //     username: `judasle7`,
    //     firstName: `Judas`,
    //     lastName: `L`
    // };

    const handleCancelRefModal = () => {
        setRefModalOpen(false);
    }

    
    useEffect(() => {
        fetchUser();
	}, []);

    const handleDisconnect = async () => {
        await tonConnect.disconnect();
    }

    useEffect(() => {
        fetchTonBalance();
        const interval = setInterval(() => {
            fetchTonBalance();
            fetchUser();
        }, 15000);
        return () => clearInterval(interval);
    }, [walletAddress]);


    const fetchUser = async () => {
        const user = await getUser(teleUser.id);
        if(user){
            const txns = await getTonTxnByUser(user.id);
            setUserData({...user, txns});
            // check wallet
            if(walletAddress && user.is_wallet_connected == 0){
                const updated = await addPoint({tg_id: teleUser.id, amount: 1000, wallet: walletAddress, point_type: 'wallet'}, initDataRaw); // connect wallet
            }
        }else{
            navigate("/", { replace: true });
        }
    };

    const fetchTonBalance = async () => {
        setTonBalance(await getTonBalance(walletAddress))
    }

    return(
        <>
            <div className="relative wallet">
                <div className="frame-2">
                    <div className="frame-2-inner">
                        <div className="head">{userData?.tele_username}</div>
                        <div className="badge-tab flex items-center justify-center gap-1">
                            <NavLink to="/wallet" className="badge-tab-item current"
                            >
                                WALLET
                            </NavLink>
                            <NavLink to="/wallet/history" className="badge-tab-item"
                            >
                                TXNS
                            </NavLink>
                        </div>

                        <div className="frame-2-content"> 
                            <div className="mb-2">
                                <div className="amount-bg point">
                                    {userData?.point}
                                </div>
                                <div className="amount-bg">
                                    {userData?.ton}
                                </div>
                            </div>

                            {
                                walletAddress ? 
                                <>
                                    <div className="leaderboards p-2 text-white mb-5 flex items-end justify-between gap-2">
                                        <div>
                                            <h2 className="text-[12px] mb-1">Connected Wallet</h2>
                                            <p className="text-[16px] ">{ truncateString(walletAddress.toString(), 4, 6) }</p>
                                            <div className="flex items-center gap-2 text-[16px] mt-3">
                                                <div>{tonBalance.toLocaleString('en-US')}</div>
                                                <img className="w-[20px]" src={tonCoin} />
                                            </div>
                                        </div>
                                        
                                        <button
                                            className="px-2 text-xs py-1 rounded-md text-white bg-[#562F00] flex justify-center items-center gap-2"
                                            onClick={() => {
                                                handleDisconnect()
                                            }}
                                        >
                                            <span>Disconnect</span>
                                        </button>
                                    </div>
                                </>
                                :
                                <div className="leaderboards p-2 text-white mb-5 flex items-end justify-center gap-2">
                                    <TonConnectButton className="ton-connect-button" />
                                </div>
                            }

                            <div className={`text-center mt-3 flex justify-center gap-2 ${walletAddress ? ``:`opacity-50 pointer-events-none`} `}>
                                <NavLink
                                    to="/wallet/deposit"
                                    className="btn is-small secondary mb-2"
                                >
                                    <span>DEPOSIT</span>
                                </NavLink>
                                <NavLink
                                    to="/wallet/deposit"
                                    className="btn is-small primary"
                                >
                                    <span>WITHDRAW</span>
                                </NavLink>
                            </div>
                            <div className="mt-5 text-center">
                                <button 
                                    onClick={ () => setRefModalOpen(true) }
                                    className="btn is-large uppercase">Invite Friends</button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            <Modal
                forceRender
                open={isRefModalOpen}
                onCancel={handleCancelRefModal}
                footer={false}
                className="modal-create-token"
            >
                <div className="text-center">
                    <div className="text-center" >
                        <div className="text-[#000]">
                            Invite friends<br/>and get more point
                        </div>
                        <img className="w-[60px] m-auto mt-3" src={logo} />
                    </div>
                    <div className="mt-5">
                        <div className="text-center mb-3 text-[#000]" >
                            Tap on the button<br />to invite your friends
                        </div>

                        <button
                            className="btn"
                            onClick={() => {
                                navigator.clipboard.writeText(`${process.env.REACT_APP_STARTAPP}?startapp=${teleUser?.id}`);
                                toast.success("Copied!");
                            }}
                        >
                            <span>Copy invite link</span>
                        </button>
                        <br />
                        <button
                            className="btn text-white mt-2"
                            onClick={ () => { utils.shareURL(`${process.env.REACT_APP_STARTAPP}?startapp=${teleUser?.id}`, ``); } }
                        >
                            <span>Share invite link</span>
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
}