import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Modal } from "antd";
import { useInitData, retrieveLaunchParams } from '@telegram-apps/sdk-react';
import { initUtils } from '@telegram-apps/sdk';
import ReactLoading from "react-loading";
import toast from "react-hot-toast";
import { getUser, createFirstSprite } from "../../web3/app";


import box from "../../assets/img/box.png";
import star from "../../assets/img/star.png";
import clock from "../../assets/img/clock.png";
import logo from "../../assets/img/logo.png";
import tonLogo from "../../assets/img/ton.png";

import social_x from "../../assets/img/social_x.png";
import social_tele from "../../assets/img/social_tele.png";
import social_docs from "../../assets/img/social_docs.png";

import rewardSprite from "../../assets/img/reward_sprite.png";
import rewardkey from "../../assets/img/reward_key.png";
import rewardItem from "../../assets/img/reward_item.png";

export default function Home() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState<any>();
    const [slots, setSlots] = useState([]);
    const [unlockedData, setUnlockedData] = useState<any>();
    const [isUnlockModalOpen, setUnlockModalOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const [isRefModalOpen, setRefModalOpen] = useState(false);

    const { initDataRaw } = retrieveLaunchParams();
    const utils = initUtils();
    const initData = useInitData();
    const teleUser = initData.user;

    // let initDataRaw;
    // let utils;
    // let ref_from = ``;
    // const teleUser = {
    //   id: `740772070`,
    //   username: `judasle7`,
    //   firstName: `Judas`,
    //   lastName: `L`
    // };

    useEffect(() => {
        fetchUser()
	}, []);

    const fetchUser = async () => {
        const user = await getUser(teleUser.id);
        if(user){
            setUserData(user)
            const filterActiveSprite = user.items.filter(item => item.item_type == `sprite` && item.active == 1);
            const slotArray = [];
            for (var i = 0; i < 4; i++) {
                if(typeof filterActiveSprite[i] !== 'undefined'){
                slotArray.push(filterActiveSprite[i])
                }else{
                    slotArray.push({})
                }
            } 
            setSlots(slotArray)

            // if(slotArray[0].id){
            //     navigate(`/sprite/${slotArray[0].id}`, { replace: false })
            // }
        }
    };

    const handleUnlockSprite = async () =>{
        if(!userData) return
        const unlocked = await createFirstSprite(userData.id, initDataRaw);
        setChecking();
        if (unlocked.status == "success") {
            setUnlockedData(unlocked.item)
        } else {
            toast.error(unlocked.message)
        }
    }

    const handleCancelRefModal = () => {
        setRefModalOpen(false);
    }

    const setChecking = async () => {
        setUnlockModalOpen(true);
        const interval = setInterval(() => {
            setIsChecked(true);
        }, 5000);
        return () => clearInterval(interval);
    }

    return(
        <div className="home-wrp">
            { !slots[0]?.id &&
            <>
                <div className="notify-box">
                    <div className="title">GIFT BOX</div>
                    <div className="inner">
                        <div>
                            <div className="text-center text-[#000]">Your first Sprite is ready</div>
                            <div className="char-img text-center">
                                <img className="m-auto w-[60px] mt-2" src={box} />
                            </div>
                            <div className="char-detail flex justify-center">
                                <button
                                    onClick={ () => handleUnlockSprite() }
                                    className="btn is-small mt-2">OPEN</button>
                            </div>
                        </div>
                    </div>
                </div>
                
                <Modal
                    forceRender
                    open={isUnlockModalOpen}
                    footer={false}
                    className="modal-char"
                >
                    <div>
                        {
                        !isChecked ? 
                        <div className="char">
                            <div className="unlocking">
                                <div className="flex justify-center mb-5 mt-10">
                                    <img className="w-[60px]" src={logo} />
                                </div>
                                <h3 className="text-center text-[20px] text-[#000] mb-5">Unlock Sprite</h3>
                                <h3 className="text-center text-[#000] mb-5">Generating randomly...</h3>
                                <div className="flex justify-center">
                                    <ReactLoading type="bars" height={30} width={30} color="#ffffff" />
                                </div>
                            </div>
                        </div>
                        : 
                        <>
                           <div className="char">
                            {
                                unlockedData &&
                                    <div className="char-content">
                                        <div className="char-head flex items-center justify-center">
                                            <div className="name text-center">{unlockedData.name} #{unlockedData.id}</div>
                                        </div>
                                        <div className="char-img">
                                            <img src={require(`../../assets/img/sprite/${unlockedData.image}`)} />
                                        </div>
                                        <div className="char-detail">
                                            <div className="flex items-center justify-center gap-3">
                                                <div className="left">
                                                    <div className="lucky">
                                                        <span className="fill" style={{
                                                        width: `calc(${(unlockedData.lucky_point/30*100)+`%`} - 27px)`
                                                    }}></span>
                                                    </div>
                                                </div>
                                                <div className="right w-[95px]">
                                                    <div className="flex items-center gap-1">
                                                        <img className="w-[22px]" src={star} />
                                                        <span className="text-[#000] font-medium text-[15px]">{unlockedData.lucky_point}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-center gap-3">
                                                <div className="left">
                                                    <div className="energy">
                                                        <span className="fill" style={{
                                                        width: `calc(${(unlockedData.mana)+`%`} - 27px)`
                                                    }}></span>
                                                    </div>
                                                </div>
                                                <div className="right w-[95px]">
                                                    <div className="flex items-center gap-1">
                                                        <img className="w-[22px]" src={clock} />
                                                        <span className="text-[#000] font-medium text-[15px]">00:00:00</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <button
                                                className="block btn m-auto mt-3"
                                                onClick={ () => { navigate(`/sprite/${unlockedData.id}`, { replace: false }); } }
                                            >
                                                <span className="uppercase">View Detail</span>
                                            </button>
                                        </div>
                                    </div>
                                }
                            </div> 
                        </>
                        }
                    </div>
                </Modal>
            </>
            }

            <div className="notify-box ">
                    <div className="title">mystery box</div>
                    <div className="inner">
                        <div>
                            <div className="text-center text-[#000] mb-2">Buy Mystery Boxes<br />to get Sprites, Keys or Items</div>
                            <div className="char-img text-center justify-center flex items-center gap-1 mb-1">
                                <img className="w-[46px] mr-3" src={box} />
                                <img className="w-[36px]" src={rewardSprite} />
                                <img className="w-[36px]" src={rewardkey} />
                                <img className="w-[36px]" src={rewardItem} />
                            </div>
                            <div className="char-detail flex justify-center mt-2 gap-1">
                                <button className="btn is-small flex items-center justify-center gap-1 opacity-50">
                                    BUY NOW
                                </button>
                                {/* <button className="btn is-small secondary uppercase">
                                    Detail
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>

            <div className="notify-box">
                <div className="title">Notification</div>
                <div className="inner">
                    <div className="text-center">
                        <div className="text-center" >
                            <div className="text-[#000]">
                                Invite friends<br/>and get more point
                            </div>
                        </div>
                        <div className="mt-3 text-center">
                            <button 
                                onClick={ () => setRefModalOpen(true) }
                                className="btn is-small uppercase">Invite</button>
                        </div>
                    </div>

                    {/* <div className="text-center">
                        <div className="text-center" >
                            <div className="text-[#000]">
                                Connect wallet<br/>and get more point
                            </div>
                        </div>
                        <div className="mt-3 text-center">
                            <button 
                                onClick={ () => {} }
                                className="btn is-small uppercase">Connect</button>
                        </div>
                    </div> */}
                </div>
            </div>
            
            <div className="notify-box">
                <div className="title">Socials</div>
                <div className="inner">
                    <div>
                        <div className="text-center" >
                            <div className="text-[#000] mb-3">
                                Follow us on:
                            </div>
                        </div>
                        <div className="socials flex gap-1">
                            <NavLink className="item" target="_blank" to="">
                                <img src={social_x} />
                            </NavLink>
                            <NavLink className="item" target="_blank" to="">
                                <img src={social_tele} />
                            </NavLink>
                            <NavLink className="item" target="_blank" to="">
                                <img src={social_docs} />
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                forceRender
                open={isRefModalOpen}
                onCancel={handleCancelRefModal}
                footer={false}
                className="modal-create-token"
            >
                <div className="text-center">
                    <div className="text-center" >
                        <div className="text-[#000]">
                            Invite friends<br/>and get more point
                        </div>
                        <img className="w-[60px] m-auto mt-3" src={logo} />
                    </div>
                    <div className="mt-5">
                        <div className="text-center mb-3 text-[#000]" >
                            Tap on the button<br />to invite your friends
                        </div>

                        <button
                            className="btn"
                            onClick={() => {
                                navigator.clipboard.writeText(`${process.env.REACT_APP_STARTAPP}?startapp=${teleUser?.id}`);
                                toast.success("Copied!");
                            }}
                        >
                            <span>Copy invite link</span>
                        </button>
                        <br />
                        <button
                            className="btn text-white mt-2"
                            onClick={ () => { utils.shareURL(`${process.env.REACT_APP_STARTAPP}?startapp=${teleUser?.id}`, `^^`); } }
                        >
                            <span>Share invite link</span>
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}