import { useEffect, useState } from "react";
import { useTonConnectUI, useTonAddress } from '@tonconnect/ui-react';
import { NavLink } from "react-router-dom";
import { TonConnectButton } from '@tonconnect/ui-react';
import { Modal } from "antd";
import { useInitData, retrieveLaunchParams } from '@telegram-apps/sdk-react';
import axios from "axios";
import ReactLoading from "react-loading";

import items_energy from "../../assets/img/items/item_energy.png";
import items_energy2 from "../../assets/img/items/item_energy2.png";
import items_energy3 from "../../assets/img/items/item_energy3.png";
import items_key from "../../assets/img/items/item_key.png";
import items_3 from "../../assets/img/items/item_3.png";
import items_4 from "../../assets/img/items/item_4.png";
import items_5 from "../../assets/img/items/item_5.png";
import items_6 from "../../assets/img/items/item_6.png";
import locked from "../../assets/img/locked.png";

export default function Home() {

    const [tonConnect] = useTonConnectUI();
    const walletAddress = useTonAddress();
    const [userData, setUserData] = useState<any>();
    const [isCheckingModalOpen, setCheckingModalOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const items_list = [
        {
            id: 1,
            name: "Energy",
            img: items_energy,
            price: 150
        },
        {
            id: 2,
            name: "Energy",
            img: items_energy2,
            price: 150
        },
        {
            id: 23,
            name: "Energy",
            img: items_energy3,
            price: 150
        },
        {
            id: 3,
            name: "Key",
            img: items_key,
            price: 150
        },{
            id: 4,
            name: "Key",
            img: items_4,
            price: 150
        },{
            id: 5,
            name: "Key",
            img: items_5,
            price: 150
        },{
            id: 6,
            name: "Key",
            img: items_6,
            price: 150
        }

    ]


    useEffect(() => {
        
	}, []);

    return(
        <>
        <div className="relative market" >
            <div className="frame-2">
                <div className="frame-2-inner">
                    <div className="head">MARKETPLACE</div>
                    <div className="badge-tab flex items-center justify-center gap-1">
                        <NavLink to="/market" className="market-tab-item "
                        >
                            SPRITE
                        </NavLink>
                        <NavLink to="/market/items" className="market-tab-item current"
                        >
                            ITEMS
                        </NavLink>
                    </div>
                    <div className="sprite-list grid grid-cols-3 gap-1 gap-y-3">
                        {
                            items_list &&
                            items_list.map((item, index) => (
                                // <NavLink to={`/items/${item.id}`} className="items-item">
                                <div className="items-item opacity-40">
                                    <div className="items-item-inner">
                                        <div className="img">
                                            <img src={item.img} />
                                        </div>
                                        <div className="quantity">{item.price}</div>
                                    </div>
                                    <button className="buy-market"></button>
                                </div>
                                // </NavLink>
                            ))
                        }
                    </div>
                </div>
            </div>
            </div>
        </>
    );
}