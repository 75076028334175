import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Modal } from "antd";
import { useInitData, retrieveLaunchParams } from '@telegram-apps/sdk-react';
import Countdown, { zeroPad } from "react-countdown";
import toast from "react-hot-toast";

import { getSpriteDetail, getUser, fighting } from '../../web3/app';
import { getMana } from '../../helpers';

import coin from "../../assets/img/coin.png";
import map1 from "../../assets/img/map1.png";

import star from "../../assets/img/star.png";
import clock from "../../assets/img/clock.png";

import win from "../../assets/img/number1.png";
import lose from "../../assets/img/close.png";
import fight from "../../assets/img/logo.png";

export default function Home() {
    const { id } = useParams();
    const [spriteData, setSpriteData] = useState<any>();
    const [userData, setUserData] = useState<any>();
    const [manaData, setManaData] = useState<any>();
    const [fightData, setFightData] = useState<any>();
    

    const [isFightModalOpen, setFightModalOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const { initDataRaw } = retrieveLaunchParams();
    const initData = useInitData();
    const teleUser = initData.user;

    // let initDataRaw;
    // let teleUser = {
    //     id: `740772070`,
    //     username: `judasle7`,
    //     firstName: `Judas`,
    //     lastName: `L`
    // };

    useEffect(() => {
        fetchSpriteDetail()
	}, [id]);

    useEffect(() => {
        fetchUser()
	}, []);

    const fetchSpriteDetail = async () => {
        const detail = await getSpriteDetail(parseInt(id));
        if(detail){ 
            setSpriteData(detail);
            setManaData(getMana(detail.mana, detail.mana_update_time));
            console.log(`mana`, getMana(detail.mana, detail.mana_update_time))
        }
    };

    const fetchUser = async () => {
        const user = await getUser(teleUser.id);
        if(user){ 
          setUserData(user);
        }
      };

    const handleFighting = async () =>{
        
        if(!userData) return
        if(!spriteData) return
        if(userData.id != spriteData.user_id) return
        // check mana
        const getM = getMana(spriteData.mana, spriteData.mana_update_time);
        if(getM.mana < 20){
            toast.error(`Not enough mana`)
            return;
        }

        const fight = await fighting(userData.id, spriteData.id, 1, initDataRaw);
        setChecking();
        if (fight.status == "success") {
            setFightData(fight.data)
            fetchSpriteDetail()
        } else {
            toast.error(fight.message)
        }
    }

    const handleCancelFightModal = () => {
        setFightModalOpen(false);
    }
    const setChecking = async () => {
        setIsChecked(false);
        setFightModalOpen(true);
        const interval = setInterval(() => {
            setIsChecked(true);
        }, 5000);
        return () => clearInterval(interval);
    }

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
          return <span className="text-[#000] font-medium text-[15px]">00:00:00</span>;
        } else {
          return (
            <span className="text-[#000] font-medium text-[15px]">{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>
          );
        }
    };  

    return(
        <>
            {
                spriteData &&
                    <div className="detail">
                    <div className="char-frame mb-3">
                        <div className="char-frame-inner">
                            <div className="char-frame-content">
                                <div className="char-head flex items-center justify-center">
                                    <div className="name">{spriteData.name} #{spriteData.id}</div>
                                </div>
                                <div className="char-img">
                                <img src={require(`../../assets/img/sprite/${spriteData.image}`)} />
                                </div>
                                <div className="char-detail">
                                    <div className="flex items-center justify-between gap-3">
                                        <div className="left">
                                            <div className="lucky">
                                                <div className="fillwrp">
                                                    <span className="fill" style={{
                                                        width: `calc(${(spriteData.lucky_point/30*100)+`%`})`
                                                    }}></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="right flex-1">
                                            <div className="flex items-center gap-1">
                                                <img className="w-[22px]" src={star} />
                                                <span className="text-[#000] font-medium text-[15px]">{spriteData.lucky_point}/30</span>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        manaData &&
                                        <div className="flex items-center justify-between gap-3 mt-1">
                                            <div className="left">
                                                <div className="energy">
                                                    <div className="fillwrp">
                                                        <span className="fill" style={{
                                                            width: `calc(${Math.floor(getMana(spriteData.mana, spriteData.mana_update_time)?.mana / 20) *20 +`%`})`
                                                        }}></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="right flex-1">
                                                <div className="flex items-center gap-1">
                                                    <img className="w-[22px]" src={clock} />
                                                    
                                                    <div className="countdown">
                                                        <Countdown
                                                            date={manaData.timeRemain * 1000}
                                                            renderer={renderer}
                                                        ></Countdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="maps">
                        <div className="maps-list-item">
                            <div className="name">Mystic Forest</div>
                            <div className="inner">
                                <div className="flex justify-center items-center gap-2">
                                    <img className="w-[120px] " src={map1} />
                                    <div className="content flex-1">
                                        <p>Win rate: <span className="font-medium">90%</span></p>
                                        <div className="flex items-center gap-1 mb-1 text-[#000] uppercase font-medium mt-1">
                                            Reward: 5<img className="w-[18px]" src={coin} />
                                        </div>
                                        <button
                                            onClick={ handleFighting  }
                                            className="btn is-small uppercase mt-1">Go Hunt</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <Modal
                forceRender
                open={isFightModalOpen}
                footer={false}
                className="modal-fight modal-char"
            >
                <div>
                    {
                    !isChecked ? 
                    <div className="char">
                        <div className="char-content text-center">
                            <img className="m-auto w-[100px] mt-7" src={fight} />
                            <p className="text-[#000]">Fighting...</p>
                        </div>
                    </div>
                    : 
                    <>
                        <div className="char">
                        {
                            fightData &&
                                <div className="char-content">
                                    {
                                        fightData.win ?
                                            <>
                                                <div className="fight-result win text-center">
                                                    <img className="m-auto w-[100px] mt-7" src={win} />
                                                    <div className="title text-[34px] text-[#000]">WIN</div>
                                                    <div className="text-[#000]">Reward: {fightData.reward}</div>
                                                </div>
                                            </>
                                            :
                                            <>
                                                <div className="fight-result lose text-center">
                                                    <img className="m-auto w-[100px] mt-5" src={lose} />
                                                    <div className="title text-[34px] text-[#000]">LOSE</div>
                                                </div>
                                            </>
                                    }

                                    <div className="text-center mt-7">
                                        <button
                                        onClick={handleCancelFightModal}
                                         className="btn is-small">OK</button>
                                    </div>
                                </div>
                            }
                        </div> 
                    </>
                    }
                </div>
            </Modal>
        </>
    );
}