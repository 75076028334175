import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getUser } from "../../web3/app";
import { useInitData, retrieveLaunchParams } from '@telegram-apps/sdk-react';

export default function Home() {
    const [userData, setUserData] = useState<any>();

    const { initDataRaw } = retrieveLaunchParams();
    const initData = useInitData();
    const teleUser = initData.user;

    // let initDataRaw;
    // let utils;
    // let ref_from = ``;
    // const teleUser = {
    //   id: `740772070`,
    //   username: `judasle7`,
    //   firstName: `Judas`,
    //   lastName: `L`
    // };

    useEffect(() => {
        fetchUser();
	}, []);

    const fetchUser = async () => {
        const user = await getUser(teleUser.id);
        if(user){ setUserData(user) }
    };

    return(
        <>
            <div className="relative">
                <div className="frame-2">
                    <div className="frame-2-inner">
                        <div className="head">YOUR BAG</div>
                        <div className="badge-tab flex items-center justify-center gap-1">
                            <NavLink to="/sprite" className="badge-tab-item current"
                            >
                                SPRITE
                            </NavLink>
                            <NavLink to="/sprite/items" className="badge-tab-item"
                            >
                                ITEMS
                            </NavLink>
                        </div>
                        {
                            userData && userData.items.length == 0 &&
                                <div className="not-found text-center text-[#000] mt-10">
                                    No Sprite found
                                </div>
                        }
                        <div className="sprite-list grid grid-cols-3 gap-1 gap-y-3">
                            { userData &&
                                userData.items.map((item, index) => (
                                    <NavLink to={`/sprite/${item.id}`} className="sprite-item">
                                        <div className="sprite-item-inner">
                                            <div className="img">
                                                {/* <img src={`${item.img}`} /> */}
                                                <img src={require(`../../assets/img/sprite/${item.image}`)} />
                                            </div>
                                            <div className="energy">{item.mana / 20}</div>
                                        </div>
                                    </NavLink>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}