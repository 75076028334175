import { useEffect, useState } from "react";
import { useInitData } from '@telegram-apps/sdk-react';
import axios from "axios";

import { getUser } from '../../web3/app';

import coin from "../../assets/img/coin.png";

import number1 from "../../assets/img/number1.png";
import number2 from "../../assets/img/number2.png";
import number3 from "../../assets/img/number3.png";

export default function Home() {

    const [userData, setUserData] = useState<any>();
    const [isCheckingModalOpen, setCheckingModalOpen] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const [leaderboards, setLeaderboards] = useState<any>([]);

    const initData = useInitData();
    const teleUser = initData.user;

    // let utils;
    // let ref_from = ``;
    // const teleUser = {
    //   id: `740772070`,
    //   username: `judasle7`,
    //   firstName: `Judas`,
    //   lastName: `L`
    // };
    

    useEffect(() => {
        fetchLeaderboard();
	}, []);
    useEffect(() => {
        fetchUser();
	}, []);

    const fetchLeaderboard = async () => {
        const result = await axios.get(`${process.env.REACT_APP_API}/users/leaderboard`);
        setLeaderboards(result.data.data);
    };

    const fetchUser = async () => {
        const user = await getUser(teleUser.id);
        if(user){ setUserData(user) }
    };

    // const leaderboards = [
    //     {
    //         id: 1,
    //         name: "strawberry1009",
    //         point: 123123234
    //     },
    //     {
    //         id: 2,
    //         name: "strawberry1009",
    //         point: 123123234
    //     },
    //     {
    //         id: 3,
    //         name: "strawberry1009",
    //         point: 123123234
    //     },
    //     {
    //         id: 4,
    //         name: "strawberry1009",
    //         point: 123123234
    //     },
    //     {
    //         id: 5,
    //         name: "strawberry1009",
    //         point: 123123234
    //     },
    //     {
    //         id: 6,
    //         name: "strawberry1009",
    //         point: 123123234
    //     },
    // ];
    // const leaderboards = [];

    return(
        <>
            <div className="relative leaderboard">
                <div className="frame-2">
                    <div className="frame-2-inner">
                        <div className="head">LEADERBOARD</div>
                        <div className="leaderboard-wrp">
                            <div className="summary">
                                <p>Total players</p>
                                <p className="uppercase text-white font-medium text-[20px]">{leaderboards.length}</p>
                            </div>
                            {
                                userData &&
                                <div className="summary flex gap-3 justify-between items-start">
                                    <div>
                                        <p>@{userData.tele_username}</p>
                                        <p className="uppercase font-medium text-[20px] text-[#FFDA9F] coin">{userData.point.toLocaleString('en-US')} <img src={coin} /></p>
                                    </div>
                                    <div className="text-[18px]">#{ leaderboards.findIndex(x => x.tele_id === userData.tele_id) + 1 }</div>
                                </div>
                            }
                            
                            <div className="leaderboards">
                                {
                                    leaderboards &&
                                        leaderboards.map((item, index) => (
                                            <div className="item flex justify-between gap-3">
                                                <div>
                                                    <p className="text-[12px] font-medium">{item.tele_username}</p>
                                                    <p className="uppercase text-white font-medium text-[18px] text-[#FFDA9F] coin">{item.point.toLocaleString('en-US')} <img src={coin} /></p>
                                                </div>
                                                <div className="text-[18px] number">
                                                    {
                                                        index == 0 ? ( <img src={ number1 } /> ) :
                                                        index == 1 ? ( <img src={ number2 } /> ) :
                                                        index == 2 ? ( <img src={ number3 } /> ) :
                                                        <span className="w-[40px] h-[40px] m-auto flex items-center justify-center rounded-full">#{index+1}</span>
                                                    }
                                                </div>
                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}