import dayjs from "dayjs";

export function getMana(mana, mana_update_time){
    if(mana_update_time == 0){
        return {
            mana: 100,
            timeRemain: dayjs().unix()
        };
    }
    const max = 14400; // 4h
    const now = dayjs().unix();
    let space = now - mana_update_time;
    if(space > max){ space = max }
    const manaLast = space * 100 / max;

    let resultMana = mana+manaLast;
    if(resultMana > 100){
        resultMana = 100;
    }

    // estimate
    let remain = 100 - resultMana;
    let timeRemain = dayjs().unix() + remain / 100 * max;
    return {
        mana: resultMana,
        timeRemain: timeRemain
    };
}