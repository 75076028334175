import { useEffect, useState } from "react";

import map1 from "../../assets/img/map1.png";
import coin from "../../assets/img/coin.png";

export default function Maps() {

    const [userData, setUserData] = useState<any>();


    useEffect(() => {
        
	}, []);

    return(
        <>
        {/* <div className="relative">
            <div className="frame-2">
                <div className="frame-2-inner">
                    <div className="badge-tab flex items-center justify-center gap-1">
                        <NavLink to="/sprite" className="badge-tab-item "
                        >
                            SPRITE
                        </NavLink>
                        <NavLink to="/sprite/items" className="badge-tab-item current"
                        >
                            ITEMS
                        </NavLink>
                    </div>
                    <div className="sprite-list grid grid-cols-3 gap-1 gap-y-3">
                        {
                            items_list &&
                            items_list.map((item, index) => (
                                // <NavLink to={`/items/${item.id}`} className="items-item">
                                <div className="items-item">
                                    <div className="items-item-inner">
                                        <div className="img">
                                            <img src={item.img} />
                                        </div>
                                        <div className="quantity">{item.energy}</div>
                                    </div>
                                </div>
                                // </NavLink>
                            ))
                        }
                    </div>
                </div>
            </div>
            </div> */}

            <div className="maps-list">
                <div className="maps-list-item">
                    <div className="name">Mystic Forest</div>
                    <div className="inner">
                        <div className="flex justify-center items-center gap-2">
                            <img className="w-[120px] " src={map1} />
                            <div className="content flex-1">
                                <p className="text-xs">Difficulty: <span className="font-medium">EASY</span></p>
                                <p className="text-xs">Reward:</p>
                                <div className="reward-list flex gap-1 mb-1">
                                    <img className="item" src={coin} />
                                </div>
                                <button className="btn is-small uppercase">View more</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="maps-list-item coming">
                    <div className="name">Mystic Forest</div>
                    <div className="inner">
                        <div className="coming-text flex items-center justify-center">
                            Coming soon ...
                        </div>
                    </div>
                </div> */}
                <div className="maps-list-item coming">
                    <div className="name">Ancient Ruins</div>
                    <div className="inner">
                        <div className="coming-text flex items-center justify-center">
                            Coming soon ...
                        </div>
                    </div>
                </div>
                <div className="maps-list-item coming">
                    <div className="name">Enchanted Cavern</div>
                    <div className="inner">
                        <div className="coming-text flex items-center justify-center">
                            Coming soon ...
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}